import React from 'react'
import Layout from '../components/layout'

const Notfound = () => (
	<Layout>
		<div className="l-wrap" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
			<h1>お探しのページは見つかりませんでした。</h1>

		</div>
	</Layout>
)

export default Notfound;